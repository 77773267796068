// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  ヘッダーメニューボタン（モバイル）
// ==================================================
const menu = document.querySelector(".header_menu-js");
const menuActiveClass = "header_menu-js-active";
const menuButton = document.querySelector(".header_menuButton-js");
const menuButtonActiveClass = "header_menuButton-js-active";
function headerMenuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  headerMenuToggle();
});

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
let headerHeight = 0;
if (is_mobile()) {
  headerHeight = 80;
}
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    /* ==================================================
     * ヘッダーメニューが開いていたら閉じる
     */
    if (menu.classList.contains(menuActiveClass)) {
      headerMenuToggle();
    }
    /* ================================================== */

    const href = anchors[i].getAttribute("href");
    if (href !== "#top" && href !== "#") {
      const target = document.getElementById(href.replace("#", ""));
      const position = window.pageYOffset + target.getBoundingClientRect().top - headerHeight;
      window.scroll({
        top: position,
        behavior: "smooth",
      });
    } else {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
  });
}

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
window.addEventListener("load", (event) => {
  const url = location.href;
  let headerHeight = 0;
  if (is_mobile()) {
    headerHeight = 80;
  }

  if (url.indexOf("#") != -1) {
    const anchor = url.split("#");
    const target = $("#" + anchor[anchor.length - 1]);
    const position = Math.floor(target.offset().top) - headerHeight;
    window.scroll({
      top: position,
      behavior: "auto",
    });
  }
});

// ==================================================
//  スライダー（Slick）
// ==================================================
$(document).ready(function () {
  // Mainvisual
  $(".mainvisual_slider-js").not(".slick-initialized").slick({
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2700,
    speed: 1800,
    fade: true,
    dots: false,
    swipe: false,
    arrows: false,
    pauseOnFocus: false,
    pauseOnHover: false,
  });
});

// ==================================================
//  追従ヘッダーの横スクロール
// ==================================================
const header = document.querySelector(".header-js"),
  mainvisual = document.querySelector(".mainvisual-js");
window.addEventListener("scroll", () => {
  header.style.left = -window.pageXOffset + "px";
  mainvisual.style.left = -window.pageXOffset + "px";
});
